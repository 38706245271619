import React, { Component } from 'react'
import './OurApproach.css';
import PrincipleCard from './PrincipleCard/PrincipleCard';
import insights from '../../contents/insights.json'
import random_pick from '../../helpers/random_pick'
import {Link} from 'react-router-dom';
import LazyLoad from 'react-lazyload';

class OurApproach extends Component {
  constructor(props){
    super(props);
    this.state = {
      isShown : false,
      in_sys : false,
      mobile_view: false,
      principle : 0,
      insights: this.random_insight(), 
      banner_color: this.banner_colors[random_pick(this.banner_colors.length, 1)[0]]
    };
  }
  
  banner_colors = ["#0839D0", "#303030", "#FC31AC", "#3D68E8", "#5A5A5A"]
  open_card(val)
  { 
    if(window.innerWidth>960) 
    {
    this.setState({isShown:true,in_sys:true, principle:val})
    }
    else
    {
      this.setState({isShown:true,in_sys:false, principle:val})
    }
  }

  handle_screen_resize = (e) => {
    if(window.innerWidth<540) 
      this.setState({in_sys:false,mobile_view: true}) 
    else if(window.innerWidth<960) 
      this.setState({in_sys:false}) 
    else 
      this.setState({mobile_view: false})
  }

  random_insight()
  {
    var output = random_pick(insights.length, 3)
    return insights[output[0]]
  }

  componentDidMount(){
    window.addEventListener('resize',this.handle_screen_resize);
  }
   componentWillUnmount(){
    window.removeEventListener('resize',this.handle_screen_resize);
   }

  render() {
    return (
      <div className="OurApproach">
        <header className="header-banner">
            <img src={this.state.mobile_view || window.innerWidth<540 ?"/assets/OurApproach/banner-mobile.jpg":"/assets/OurApproach/banner.jpg"} alt="Our Approach"></img>
            <section className="header-banner-content">
                <h1>Our approach</h1>
                <p>Outcomes-driven partnership mindset</p>
            </section>
        </header>
        <section className="row section1">

        <section className="left section1-header">
          <h1 className="margin-bottom-15">The partnership mindset</h1>
        </section>

        <section className="right section1-content">
          <p>Technology and analytics are but tools that are used to drive business outcomes. We recognize that companies often struggle with translating business problems to technology or analytics solutions. We also recognize that your vision when you start out is often broad and cloudy. Our journey starts with understanding your business context and helping think through the problem bottom-up. Through immersive exploration, we seek to methodically and creatively define the possibilities given the business outcome you seek to drive. We will function as partners rather than vendors.</p>
          <p>With deep rooted experience in building scalable enterprise applications, widespread expertise in analytics across many industries and functional areas, a culture of innovation, and an unflinching eye on user experience, we strive to integrate all aspects of digital transformation for an enterprise. Regardless of where you are in your transformation journey, Acies can help chart a course and move the needle.</p>
        </section>
        </section>
        
        <section className="row row-hex">
        <div className="hex-heading">First principles based thinking and iterative collaboration with our clients</div>
          <section className="right OurApproach-hexagon-row">
            
            <div className="row12">
            <div className="hexagons">
              <div className="hex1 pointer hover-effects" onMouseEnter={() => this.open_card(0)} onMouseLeave={() => this.setState({isShown:false,in_sys:false})}>
                <img src = {"/assets/OurApproach/Hexagon/Yellow.png"} alt={"Visualize & Engage"} className="OurApproach-hexagon-img"/>
                <div className={this.state.principle === 0 && window.innerWidth>960 ? 'show' : 'hidden'}>
                  <PrincipleCard principle={this.state.principle} />
                </div> 
              </div>
              <div className="hex2 pointer hover-effects" onMouseEnter={() => this.open_card(1)} onMouseLeave={() => this.setState({isShown:false,in_sys:false, principle: 0})}>
                <img src = {"/assets/OurApproach/Hexagon/Black.png"} alt={"Think Ahead"} className="OurApproach-hexagon-img"/>   
                <div className={this.state.principle === 1 && this.state.isShown && this.state.in_sys && window.innerWidth>960 ? 'show' : 'hidden'}>
                  <PrincipleCard principle={this.state.principle} />
                </div> 
              </div>
              <div className="hex3 pointer hover-effects" onMouseEnter={() => this.open_card(2)} onMouseLeave={() => this.setState({isShown:false,in_sys:false, principle:0})}>
                <img src = {"/assets/OurApproach/Hexagon/Gray.png"} alt={"Eliminate failures"} className="OurApproach-hexagon-img"/>
                <div className={this.state.principle === 2 && this.state.isShown && this.state.in_sys && window.innerWidth>960 ? 'show' : 'hidden'}>
                  <PrincipleCard principle={this.state.principle} />
                </div> 
              </div> 
             
            </div>
            <div className={window.innerWidth<=960? 'show' : 'hidden'}>
                  <PrincipleCard principle={this.state.principle} />
                </div> 
            </div>
            <p>The three pillars of our approach to problem solving</p>
          </section>
          
        </section>

        <Link className="row section-3" to={this.state.insights.path}>
          <section className="left section3-text" style={{backgroundColor:this.state.banner_color}}>
            <div>{this.state.insights.heading}</div>
          </section>
          <section className="right margin-0">
            <LazyLoad once offset={100} style={{height:"100%"}}>
            <img src={this.state.insights.banner_image} alt="Banner" className="OurApproach-section3-img" />
            </LazyLoad>
          </section>
        </Link>

      </div>
      
    );
  }
}

export default OurApproach;