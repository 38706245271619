import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import './AdaptiveSolutions.css';
import case_studies from '../../contents/banner_case_study.json'
import {Link} from 'react-router-dom';
import random_pick from '../../helpers/random_pick'

class AdaptiveSolutions extends Component {
  constructor(props){
    super(props);
    this.state = {
      case_studies : case_studies[0],
      mobile_view: false,
      banner_color: this.banner_colors[random_pick(this.banner_colors.length, 1)[0]]
    };
  }
  banner_colors = ["#0839D0", "#303030", "#FC31AC", "#3D68E8", "#5A5A5A"]

  handle_screen_resize = (e) => {
    if(window.innerWidth<540) 
        this.setState({mobile_view: true})
    else 
        this.setState({mobile_view: false})
  }

  // componentWillMount(){}
   componentDidMount(){
    window.addEventListener('resize',this.handle_screen_resize);
    let index = this.getRandomNumber(case_studies.length);
    this.setState({case_studies:case_studies[index]});
   }
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}
  
  componentWillUnmount(){
      window.removeEventListener('resize',this.handle_screen_resize);
  }

  getRandomNumber(input_length) {
    return Math.floor(Math.random() * input_length);
}

  render() {
    return (
    <div className="AdaptiveSolutions">
      <header className="header-banner">
          <img src={this.state.mobile_view || window.innerWidth<540 ?"/assets/AdaptiveSolutions/banner-mobile.jpg":"/assets/AdaptiveSolutions/banner.jpg"} alt="Adaptive solutions"></img>
          <section className="header-banner-content">
              <h1>Adaptive Solutions</h1>
              <p>Bringing together technology and insights - long term thinking with practical implementation</p>
          </section>
      </header>
      <section className="row1 section">
        <section className="left1 section-left blue font-25 white-text">
          <h1>What are Adaptive Solutions?</h1>
        </section>
        <LazyLoad once offset={100} className="right1 section-right">
          {/* <div className="section-image img2"></div> */}
          <img src={"/assets/AdaptiveSolutions/Image2/Image2.jpg"} alt="Banner" className="AdaptiveSolutions-Image2" />
        </LazyLoad>
      </section>

      <section className="row1 row1-text">
        
          <p>Technology and Insights form the crux of the transformation journey. Traditionally, many companies have struggled to operationalize insights at scale. PowerPoint and Excel based outputs from analytics solutions die out over time if they are not integrated with operational processes and technology applications that are in use every day. On the flip side, analytics has been an after-thought for many systems and solutions viewed and developed through a technology lens. This means that a lot of answers to operational questions have to be tackled outside of solutions already in place; creating more Excels and PowerPoints. The best solutions are those that allow for data-based insights within operational enterprise solutions - we call these Adaptive Solutions. While our end vision is to help build Adaptive Solutions that break the silos to bring together technology and analytics; we also recognize that your current need may be very specific. Every business is at a different point in its digital transformation journey. Our engagement framework allows us to meet you at any point in your journey, walk with you, and deliver incremental outcomes.</p>
        
      </section>

      <section className="row1 section">
        <section className="left1 section-left pink font-25 white-text">
          <h1>How can Adaptive Solutions be made real?</h1>
        </section>
        <LazyLoad once offset={100} className="right1 section-right">
          {/* <div className="section-image img1"></div> */}
          <img src={"/assets/AdaptiveSolutions/Image1/Image1.jpg"} alt="Banner" className="AdaptiveSolutions-Image2" />
        </LazyLoad>
      </section>

      <section className="row1 row1-text">
          <p>How do we do this? Information Technology has developed rigorous processes over time that delivers to specifications and helps mitigate risks. Analytics, on the other hand, requires flexibility and agility to change tracks quickly and adapt to new discoveries and hypotheses from the data. Enabling transformative solutions needs a mix of processes that brings out the best of both worlds. With dedicated teams that work with you from start to finish, we bring a ‘product mindset’ to the journey and apply that thinking across the various stages of solution development. This allows us to not just anticipate your present needs , but also plan for the future. Our teams are structured as Agile Pods for maximum innovation and faster delivery times for your solution.</p>
      </section>

      
      <Link to={{pathname:this.state.case_studies.path,content:{item:this.state.case_studies}}} className="row2 section123">
        <section className="right1 section-right left2" style={{backgroundColor:this.state.banner_color}}>
          <h1 className="margin-bottom-15">{this.state.case_studies.banner_heading}</h1>
          <p>{this.state.case_studies.content}</p>
        </section>
        <LazyLoad once offset={100} className="left1" style={{width: "46.1%"}}>
          <img className="AdaptiveSolutions-Image3" src={this.state.case_studies.home_image} alt="Case Study"></img> 
        </LazyLoad>  
      </Link>
    </div>
    );
  }
}

export default AdaptiveSolutions;