import React, { Component } from 'react';
import './ContactUs.css';
import ReCAPTCHA from "react-google-recaptcha";

class ContactUs extends Component {
  constructor(props){
    super(props);
    this.state = {
      mobile_view: false,
      name: "",
      designation: "",
      organisation: "",
      email: "",
      message: "",
      token:"",
      isToken: false,
      submit_message: "",
      success_message: {
        line1 : "",
        line2 : ""
      },
      form_validation: {
        name: false,
        designation: false,
        organisation: false,
        email: false,
        message: false
      }
    };
  }

  handle_screen_resize = (e) => 
    {
      this.setState({mobile_view: window.innerWidth<540})
    }
  
  form_submit = () => {
    
    if(!this.validate_all())
    {
      this.setState({submit_message:"Please fill in required fields"})
    }
    else if(!this.validate_mail(this.state.email))
    {
      this.setState({submit_message:"Invalid mail id"})
    }
    else 
    { 
      this.setState({submit_message:"Submitting..."})
      let req_body = {
        name: this.state.name,
        designation: this.state.designation,
        organisation: this.state.organisation,
        email: this.state.email,
        message: this.state.message,
        token: this.state.token
      }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
        };
       fetch(process.env.REACT_APP_PIPEDREAM_URL , requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.success) 
          {
            this.setState({submit_message:"", success_message:{line1:"Thank you for reaching out to us. We have received your message.",line2:"We will contact you shortly."}})
            this.reset_form();
          }
          else 
          {
            this.setState({submit_message:"Failed! Please try again"})
          }
        })
        .catch(()=>this.setState({submit_message:"Failed! Please try again"}))
        setTimeout(()=>this.captcha.reset(),5000)
    }
  }

  validate_all()
  {
    if(this.state.name.trim() === '' || this.state.designation.trim() === '' ||
       this.state.organisation.trim() === '' || this.state.email.trim() === '' ||
        this.state.message.trim() === '')
    {
      return false
    }
    return true
  }
  validate_field(event,field_name)
  { 
    let temp = this.state.form_validation
    if(event.target.value.trim() === '')
    {
      temp[field_name] = true
    }
    else if(field_name === "email" && !this.validate_mail(event.target.value))
    {
      temp[field_name] = true
    }
    else temp[field_name] = false
    this.setState({[field_name] : event.target.value, form_validation:temp});
  }

  validate_mail(mail)
  {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
    {
      return true
    }
    return false
  }

  reset_form()
  {
    var fields = document.getElementsByClassName("input-field");
    for(var i=0;i<fields.length;i++)
    {
      fields[i].value = "";
    }
  }

  componentDidMount(){
    window.addEventListener('resize',this.handle_screen_resize);
  }
  componentWillUnmount(){
    window.removeEventListener('resize',this.handle_screen_resize);
  }

  render() {
    return (
      <div className="contact-us">
        <header className="header-banner contact-us-banner-1">
              <img src={this.state.mobile_view || window.innerWidth<540 ?"/assets/Banners/contact-us-mobile.jpg":"/assets/Banners/contact-us.jpg"} alt="Contact"></img>
              <h1 className="contact-us-banner-1-heading">Contact</h1>
      </header>
        <div className="address">
          <div>
            <h3>United States</h3>
            <div>21791 N Tall Oaks Drive</div>
            <div>Kildeer IL 60047</div>
            <div>Ph: + 1(847) 769 3938</div>
          </div>
          <div>
            <h3>India</h3>
            <div>Workafella</div>
            <div>No. 431, Anna Salai</div>
            <div>Chennai 600018</div>
            <div>Tamil Nadu, India</div>
          </div>
        </div>
        <div className="contact-us-main">
          <div className="google-map">
            <iframe title="Acies" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15548.29029286694!2d80.1679161!3d13.0310504!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe212d5d97710e9c9!2sAcies%20Global!5e0!3m2!1sen!2sin!4v1618299586616!5m2!1sen!2sin" 
            width="600" height="450" style={{border:"0",width:"100%",height:"100%"}} allowFullScreen="" loading="lazy"></iframe>
          </div>
          <div className="contact-us-form">
              <div className="contact-us-form-header">Contact Us</div>
              <div>
                <div>
                    <input className="input-field" style={{border:this.state.form_validation.name?"1px solid red":"1px solid #C4C4C4"}} placeholder="Name" onChange={e => this.validate_field(e,"name")} autoComplete="true"></input>
                    {/* <i class="fa fa-exclamation-circle" style={{fontSize:"18px",color:"red",display:this.state.form_validation.name?"":"none"}}></i> */}
                    <input className="input-field" style={{border:this.state.form_validation.designation?"1px solid red":"1px solid #C4C4C4"}} placeholder="Designation" onChange={e => this.validate_field(e,"designation")} autoComplete="true"></input>
                    <input className="input-field" style={{border:this.state.form_validation.organisation?"1px solid red":"1px solid #C4C4C4"}} placeholder="Organisation" onChange={e => this.validate_field(e,"organisation")} autoComplete="true"></input>
                    <input className="input-field" style={{border:this.state.form_validation.email?"1px solid red":"1px solid #C4C4C4"}} placeholder="Email" onChange={e => this.validate_field(e,"email")} autoComplete="true"></input>
                    <textarea className="input-field" style={{border:this.state.form_validation.message?"1px solid red":"1px solid #C4C4C4"}}  placeholder="Your message" rows="5" onChange={e => this.validate_field(e,"message")} autoComplete="true"></textarea>
                    <div className="recaptcha">
                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}  
                                onChange={token => this.setState({token: token, isToken:true})}
                                ref={(r) => this.captcha = r}  >            
                    </ReCAPTCHA>
                    </div>
                    <button className="submit-button" onClick={this.form_submit} disabled={!this.state.isToken} style={{cursor:!this.state.isToken?"not-allowed":"pointer"}}  >Submit</button>
                    <div style={{display:this.state.mobile_view?"block":"inline-block"}} className="submit-message">{this.state.submit_message}</div>
                    <div className="success-message">
                      <div>{this.state.success_message.line1}</div>
                      <div>{this.state.success_message.line2}</div>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;