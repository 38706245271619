import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import './Home.css';
import case_studies from '../../contents/banner_case_study.json'
import {Link} from 'react-router-dom';
import insights from '../../contents/insights.json'
import banner2 from '../../assets/home-image-2.jpg'
import random_pick from '../../helpers/random_pick'

class Home extends Component {
  constructor(props){
    super(props);
    this.state = {
      case_studies: [],
      insights: insights,
      mobile_view: false,
      case_study_banner: case_studies[1],
      banner_color: this.banner_colors[random_pick(this.banner_colors.length, 1)[0]]
    };
  }
  banner_colors = ["#0839D0", "#303030", "#FC31AC", "#3D68E8", "#5A5A5A"]
  random_pick(input_length, random_count) {
    var arr=[]
    while(arr.length < random_count) 
    {
        var randomNumber = Math.random();
        var ind  = Math.floor(randomNumber * input_length);
        if(!arr.includes(ind))
        {
            arr.push(ind)
        }
    }
    return arr;
}

handle_screen_resize = (e) => {
  if(window.innerWidth<540) 
    this.setState({in_sys:false,mobile_view: true}) 
  else 
    this.setState({mobile_view: false})
}

  componentDidMount(){
    window.addEventListener('resize',this.handle_screen_resize);
    var output = this.random_pick(case_studies.length, 5)
    var result = []
    for(var i=0;i<output.length-1;i++)
    {
        result.push(case_studies[output[i]]);
    }
    this.setState({case_studies:result,case_study_banner:case_studies[output[4]],banner_color: this.banner_colors[random_pick(this.banner_colors.length, 1)[0]]});

  }
  componentWillUnmount(){
    window.removeEventListener('resize',this.handle_screen_resize);
   }

  render() {
    return (
      <>
        <div className="home">
        <header className="header-banner">
            <img src={this.state.mobile_view || window.innerWidth<540 ?"/assets/Home/banner-mobile.jpg":"/assets/Home/banner.jpg"} alt="Home"></img>
            <section className="header-banner-content">
              <div className="home-banner-1-left-content-1">
                  <div><span style={{color: "#FFFFFF"}}>Discover your edge</span><br style={window.innerWidth<960?{display:"block"}:{display:"none"}}/><span style={{color: "#FDD764"}}> through data driven transformation </span></div>
              </div>
              <p className="home-banner-1-left-content-2" >
              In an increasingly hypercompetitive and changing business landscape, companies need to constantly innovate and evolve in order to stay relevant. 
              At Acies, we help you leverage your data assets to chart a digital transformation journey and sharpen your edge.
              </p>
            </section>
        </header>
        <div className="home-banner-4">
              <span style={{fontWeight:"bolder"}}>Acies</span> - Latin for "edge" or "sharpness", helps businesses accelerate their digital transformation journey, 
              sharpening the vision and execution.
        </div>
        <div className="home-banner-2">
            <LazyLoad once offset={100} className="home-banner-2-left">
                <img src={banner2}  alt="business needs"></img>
            </LazyLoad>
            <div className="home-banner-2-right">
                <h1>Transformative solutions that evolve with your needs</h1>
                <p>Digital Transformation is the integration of digital technology into all aspects of your business. 
                  The journey encompasses mapping business processes, identifying opportunities for improvement through digital interventions, and making use of the most appropriate technology and data assets to disrupt existing practices. From automation of manual processes to deeper real time insights about your operations, and enhanced customer engagement, 
                  digital transformation has the ability to touch every facet of your business.</p>
                <p>Digital transformation is no more a choice - it is a constant introspective and iterative journey to reinvent oneself. What this needs is a learning mindset, consistent exploration of evolving technology and tools, a culture of data-based decision making, and a thirst to enhance end-user experience over and over again.</p>
            </div>
        </div>
        <Link className="home-banner-5" to={this.state.case_study_banner.path}>
              <div className="home-banner-5-left" style={{backgroundColor:this.state.banner_color}}>
                <div className="home-banner-5-left-1">{this.state.case_study_banner.banner_heading}</div>
                <p className="home-banner-5-left-2">
                  {this.state.case_study_banner.content}
                </p>
              </div>
              <LazyLoad once offset={100}  className="home-banner-5-right">
                  <img  src={this.state.case_study_banner.home_image} alt=""></img>
              </LazyLoad>
        </Link>
        <div className="home-banner-6">
              <div className="read-article">Insights</div>
              <LazyLoad once offset={100}  className="home-banner-6-contents">
                {this.state.insights.map((item, methodIndex) => {
                      return( 
                          <Link to={{pathname:item.path, content:{item}}} key={methodIndex} className="home-banner-6-content">
                              <img className="home-banner-6-content-image" src={item.image} alt=""/>
                              <div className="home-banner-6-content-name">{item.heading}</div>
                              <p className="home-banner-6-content-info">{item.content}</p>
                          </Link>
                      )
                      })}
              </LazyLoad>
        </div>
      </div>
      </>
    );
  }
}
export default Home;