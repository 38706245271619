import React, { Component } from 'react';
import './Navigation.css';
import {Link, NavLink} from 'react-router-dom';
import hide from '../../App'
import logo from '../../assets/logo.svg'
import case_studies from '../../contents/case_study.json'

class Navigation extends Component {
  constructor(props){
    super(props);
    this.state = {
      sideBar : false, 
      service_popup : true,
      case_study_popup : true,
      service_drop_down: true,
      casestudy_drop_down: true,
    };

    this.hide = hide.bind(this)
  }

  nav_items = [
    {name:'Home', path:'/home'},
    {name:'Services', path:'/services'},
    {name:'Our Approach', path:'/our-approach'},
    {name:'Team', path:'/team'},
    {name:'About Us', path:'/about-us'},
    {name:'Case Studies', path:'/case-studies'},
    {name:'Insights', path:'/insights'},
    {name:'Contact', path:'/contact'}
  ];

  service_items = [
    {name:'Enterprise Application', image:'Mask Group 54.png',path:"enterprise-application"},
    {name:'Data Engineering', image:'Mask Group 53.png',path:"data-engineering"},
    {name:'Business Analytics', image:'Group 673.png',path:"business-analytics"},
    {name:'Cloud Services', image:'Mask Group 37.png',path:"cloud-services"},
    {name:'Enterprise Mobility', image:'Mask Group 39.png',path:"enterprise-mobility"} 
  ]

  handle_screen_resize = (e) => {
    if(window.innerWidth>1150) 
    {
      this.setState({sideBar:false, service_popup:true, case_study_popup:true})
    }
    else
    {
      this.setState({case_study_popup:false})
    }
    
  }

  on_select = (index,item) =>
  { 
    if(index===1 && window.innerWidth<1150)
    { 
      this.setState({service_drop_down:!this.state.service_drop_down})
    }
  }
  
  show_dropdown = (index) =>
  { 
    if(window.innerWidth>1150)
    {
      if(index === 1) this.setState({service_drop_down:false})
      else if(index === 5) this.setState({casestudy_drop_down:false})
    }
    
  }

  hide_drop_down = (index) =>
  {
    if(index === 1) this.setState({service_drop_down:true})
    else if(index === 5) this.setState({casestudy_drop_down:true})
  }


  // componentWillMount(){}
  componentDidMount(){
    window.addEventListener('resize',this.handle_screen_resize);
  }
   componentWillUnmount(){
    window.removeEventListener('resize',this.handle_screen_resize);
   }

  render() {
    return (
      <div className="Nav-bar">
        <div className="left-content">
          <img src={logo} className="logo" onClick={()=>window.location.href='/home'} alt=""></img>
          <div className="hamburger-icon" onClick={() => {this.setState({sideBar:true})}}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className={this.state.sideBar?"side-bar":"right-content"}>
          <div className={this.state.sideBar?"open-cross-mark":"close-cross-mark"} onClick={() => {this.setState({sideBar:false})}}>X</div>
          {this.nav_items.map((item, index) => (
             <NavLink to={item.path} key={index} activeClassName="select" onClick={() => {this.on_select(index,item)}}  onMouseOver={() => this.show_dropdown(index)} onMouseLeave={() => this.hide_drop_down(index)}>
                {item.name}
                {(()=>{if(index===1)
                  { 
                    return(
                    <>
                    <i className="arrow down" ></i>
                    <div className="service-popup"  style={this.state.service_drop_down?{display:"none"}:{display:"block"}}>
                      {this.service_items.map((item,index) =>(
                        <Link to={"/services/"+item.path} key={index} className="service-popup-content" onClick={() => {this.setState({sideBar:false, service_drop_down:true})}}>{item.name}</Link>
                      )
                      )}
                    </div></>)
                  }
                  if(index===5)
                  { 
                    return(
                    <>
                    <i className="arrow down" style={{display:this.state.case_study_popup && window.innerWidth>1150?"":"none"}}></i>
                    <div className="case-study-popup" style={this.state.casestudy_drop_down?{display:"none"}:{display:"flex"}}>
                      {case_studies.map((item,index) =>(
                        <Link to={item.path} key={index} className="case-study-popup-content" onClick={() => {this.setState({sideBar:false, casestudy_drop_down:true})}}>{item.title}</Link>
                      )
                      )}
                    </div></>)
                  }
                })()
                }
              </NavLink>   
          ))}
        </div>
      </div>
    );
  }
}

export default Navigation;