import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import './AboutUs.css';
import Card from './Card/Card';
import LeftImageRow from './LeftImageRow/LeftImageRow';
import RightImageRow from './RightImageRow/RightImageRow';
import CardInfo from './CardInfo.json';
class AboutUs extends Component {
    constructor(props){
        super(props);
        this.state = {
          mobile_view: false
        };
      }

    handle_screen_resize = (e) => 
    {
             this.setState({mobile_view: window.innerWidth<540})
    }

    componentDidMount(){
        window.addEventListener('resize',this.handle_screen_resize);
    }
    
    componentWillUnmount(){
        window.removeEventListener('resize',this.handle_screen_resize);
    }

    render() { 
        
        return ( 
            <div className="AboutUs">
                <header className="header-banner">
                    <img src={this.state.mobile_view || window.innerWidth<540 ?"/assets/AboutUs/banner-mobile.jpg":"/assets/AboutUs/banner.jpg"} alt="About us"></img>
                    <section className="header-banner-content">
                        <h1>About Us</h1>
                        <p>Partners in your digital journey</p>
                    </section>
                </header>

                <section className="section1 center-text">
                    <h1>Who we are</h1>
                    <p>We are a passionate team of digital scientists united in a mission to enable our clients in their transformation journey. We bring together a rare mix of technology and analytics experience to help clients optimize their operations and delight their customers. We see ourselves as true partners - immersing ourselves in your business, working with you in uncovering and defining latent opportunities, and designing solutions that are scalable. We take pride in our work and experience joy in helping our clients find their digital edge.</p>
                </section>

                <section className="section2 white-text center-text">
                    <h1>What drives us</h1>
                    <div className="cards">
                        {CardInfo.map((card,index) => <Card
                                                key={index}
                                                image={card.image}
                                                text={card.text}
                                                title={card.title}/>)} 
                    </div>
                    
                </section>
            
 {/*                <section className="section3  center-text">
                    <header>
                        <h1>Leadership Team</h1>
                    </header>
                    <LazyLoad once offset={150}>
                        <LeftImageRow 
                            image={"/assets/AboutUs/Website_Mukund.png"}
                            name="Mukund Raghunath"
                            role="CHIEF EXECUTIVE OFFICER"
                            text="Mukund is Co-Founder and CEO of Acies Global, a digital transformation services company serving Fortune 500 clients across the globe. Prior to Acies, Mukund was a founding employee and part of the executive leadership team of Mu Sigma, which grew to be the largest pure play analytics company in the world. Mukund has proven experience in building and managing large technology and analytics teams across multiple geographies and industry verticals. He has been a trusted advisor to senior leadership of multiple Fortune 500 companies, helping them with their digital transformation strategy and execution. Mukund has an MBA (Honors) from the University of Chicago, Booth School of Business. Mukund is also an active angel investor and holds advisory/board positions in several start-ups in the technology space."
                        />
                        
                    </LazyLoad>
                       
                </section>*/}
            </div>
         );
    }
}
 
export default AboutUs;
