import React, { Component } from 'react';
import './Footer.css';
import Navigation from '../Navigation/Navigation';
import {Link} from 'react-router-dom';

class Footer extends Component {
  constructor(props){
    super(props);
    this.state = {};
    this.service_items = new Navigation().service_items;
  }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div className='footer'>
        <div className="footer-left">
            <div className="services">
              <Link to="/services" className="heading">Services</Link>
              <div className="content">
                {this.service_items.map((item, index) => (
                  <Link to={"/services/"+item.path} key={index}>{item.name}</Link>
                ))}
              </div>
            </div>
            <div className="about-us">
              <Link to="/about-us" className="heading">About Us</Link>
              <div className="content">
                <Link to="/about-us">About Us</Link>
                <Link to="/our-approach">Our approach</Link>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfz5wpv8FUyQcddFnelZaeGE-T4S0r6wen-MtKpGqwVd_oqcw/viewform" target="_blank" rel="noreferrer">Join us</a>
              </div>
            </div>
            <div className="insights">
              <Link to="/insights" className="heading">Insights</Link>
              <div className="content">
                <Link to="/case-studies">Case Studies</Link>
                <Link to="/insights">Insights</Link>
              </div>
            </div>         
        </div>
        <div className="copyright">
            <p>Copyright © 2022. &nbsp;Acies. &nbsp;All rights reserved.</p>
        </div>
      </div>
    );
  }
}

export default Footer;