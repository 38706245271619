import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import './Services.css';
import {Link} from 'react-router-dom';
import case_studies from '../../contents/banner_case_study.json'
import random_pick from '../../helpers/random_pick'

class services extends Component {
  service_items = [
    {name:'Enterprise Application', image:'enterprise-applications.png',path:"enterprise-application"},
    {name:'Data Engineering', image:'data-engineering.png',path:"data-engineering"},
    {name:'Business Analytics', image:'business-analytics.jpg',path:"business-analytics"},
    {name:'Cloud Services', image:'cloud-services.png',path:"cloud-services"},
    {name:'Enterprise Mobility', image:'enterprise-mobility.png',path:"enterprise-mobility"}
  ];
  constructor(props){
    
    super(props);
    this.state = {
      basePath:"../../assets/Services/",
      caseStudies:case_studies,
      serviceItems: this.service_items,
      currentIndex:2,
      arrowStyle:{flexDirection:"row"},
      prevArrowStyle:{cursor:"auto",
      },
      nextArrowStyle:{cursor:"pointer"},
      banner_color: random_pick(this.banner_colors.length, 3),
      mobile_view: false
    };
    this.toggleServiceToPrev=this.toggleServiceToPrev.bind(this);
    this.toggleServiceToNext=this.toggleServiceToNext.bind(this);
    this.toggleService=this.toggleService.bind(this);
  }
  banner_colors = ["#0839D0", "#303030", "#FC31AC", "#5A5A5A"]
  random_pick(input_length, random_count) {
    var arr=[]
    while(arr.length < random_count) 
    {
        var randomNumber = Math.random();
        var ind  = Math.floor(randomNumber * input_length);
        if(!arr.includes(ind))
        {
            arr.push(ind)
        }
    }
    return arr;
}
handle_screen_resize = (e) => {
  
  if(window.innerWidth<540) 
  {
    this.setState({mobile_view: true})
  }
  else
  {
    this.setState({mobile_view: false})
  }
}
componentDidMount(){
  window.addEventListener('resize',this.handle_screen_resize);
  var output = this.random_pick(case_studies.length, 3)
  var result = []
  for(var i=0;i<output.length;i++)
  {
      result.push(case_studies[output[i]]);
  }
  
  this.setState({caseStudies:result});
}

 toggleServiceToPrev(){
    if(this.state.currentIndex>2){
      this.setState({serviceItems:this.service_items.slice(0,3)})
      this.setState({currentIndex:2})
      this.setState({prevArrowStyle:{cursor:"auto"}})
      this.setState({nextArrowStyle:{cursor:"auto"}})
     
      this.setState({arrowStyle:{flexDirection:"row"}})
    }
    
}
toggleServiceToNext(){
  if(this.state.currentIndex === 2){
    this.setState({serviceItems:this.service_items.slice(2,5)})
    this.setState({currentIndex:4})
    this.setState({prevArrowStyle:{cursor:"auto",transform:"rotate(180deg)"}})
    this.setState({nextArrowStyle:{cursor:"pointer",transform:"rotate(180deg)"}})
    this.setState({arrowStyle:{flexDirection:"row-reverse"}})
  }
}

  toggleService(){
    if(this.state.currentIndex>2){
      this.setState({serviceItems:this.service_items.slice(0,3)})
      this.setState({currentIndex:2})
      this.setState({prevArrowStyle:{cursor:"auto"}})
      this.setState({nextArrowStyle:{cursor:"pointer"}})
     
      this.setState({arrowStyle:{flexDirection:"row"}})
    }
    else if(this.state.currentIndex === 2){
      this.setState({serviceItems:this.service_items.slice(2,5)});
      this.setState({currentIndex:4});
      this.setState({prevArrowStyle:{cursor:"auto",transform:"rotate(180deg)"}});
      this.setState({nextArrowStyle:{cursor:"pointer",transform:"rotate(180deg)"}});
      this.setState({arrowStyle:{flexDirection:"row-reverse"}});
    } 
}

  componentWillUnmount(){
    window.removeEventListener('resize',this.handle_screen_resize);
  }
  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
   
  render() {
    return (
  <div className="services-home">  
    <header className="header-banner">
          <img src={this.state.mobile_view || window.innerWidth<540 ?"/assets/Services/banner-mobile.jpg":"/assets/Services/banner.jpg"} alt="Services"></img>
          <section className="header-banner-content">
              <h1>Services</h1>
              <p>We bring years of experience across all aspects of the digital journey to help transform your business, technology, and operations</p>
          </section>
    </header>
  <p className="services-row-2">
  From automation of manual processes to deeper real time insights about your operations, and enhanced customer engagement, digital transformation has the ability to touch every facet of your business.
  </p>
  <div className="services-row-3">
    <div className="services-row-3-header">
    <div className="services-row-3-left-header">
   
      Services
    
    </div>
    </div>
    <LazyLoad once offset={100} className="services-row-3-content">
   
    {this.state.serviceItems.map((item, index) => (
         <Link to={this.props.match.url+"/"+item.path} key={index} className="services-image-banner">
             <img src={"/assets/Services/"+item.image} className="services-image" alt=""></img>
             <div className="services-image-name">{item.name}</div>
          </Link>   
      ))}
           
    </LazyLoad>
    
  </div>
  <div className="services-row-4">
    <div className="services-row-4-header">
    Digital transformation across sectors
    </div>
  </div>
  <Link to={{pathname:this.state.caseStudies[0].path, content:{item:this.state.caseStudies[0]}}} className="services-row-5">
  <LazyLoad once offset={100} className="services-row-5-left-content">
    
      <img src={this.state.caseStudies[0].services_image} alt=""></img>
    </LazyLoad>
    <div className="services-row-5-right-content" style={{backgroundColor:this.banner_colors[this.state.banner_color[0]]}}>
     
      <div className="services-row-5-right-content-1">{this.state.caseStudies[0].banner_heading}</div>
      <p className="services-row-5-right-content-2">{this.state.caseStudies[0].content}</p>
     
      </div>
    </Link>
    <Link to={{pathname:this.state.caseStudies[1].path, content:{item:this.state.caseStudies[1]}}} className="services-row-6">
  <LazyLoad once offset={100} className="services-row-6-left-content">
      <img src={this.state.caseStudies[1].home_image} alt=""></img>
    </LazyLoad>
    <div className="services-row-6-right-content" style={{backgroundColor:this.banner_colors[this.state.banner_color[1]]}}>
      <div className="services-row-6-right-content-1">{this.state.caseStudies[1].banner_heading}</div>
      <p className="services-row-6-right-content-2"> {this.state.caseStudies[1].content}</p>
      </div>
    </Link>
    <Link to={{pathname:this.state.caseStudies[2].path, content:{item:this.state.caseStudies[2]}}} className="services-row-7">
    <div className="services-row-7-left-content" style={{backgroundColor:this.banner_colors[this.state.banner_color[2]]}}>
    <div className="services-row-7-left-content-1">
    {this.state.caseStudies[2].banner_heading}
      </div>
      <p className="services-row-7-left-content-2">
      {this.state.caseStudies[2].content}
      </p>
    </div>
    <LazyLoad once offset={100} className="services-row-7-right-content">
      <img src={this.state.caseStudies[2].home_image} alt=""></img>
      </LazyLoad>
    </Link>
  </div>
    );
  }
}

export default services;