import React, { Component } from 'react';
import './ReachOutForm.css';

class ReachOutForm extends Component {
  // constructor(props){
    // super(props);
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div className="reach-out-form">
         <div className="top-reach-out">
           <div>How can we help?</div>
         </div>
         <div className="bottom-reach-out">
           <div className="box" style={{cursor:"unset"}}>
             <div className="reach-out-heading">Call us</div>
             <div className="icon phone-icon"></div>
             <div className="contact-info">+1 (847) 769 3938</div>
           </div>
           <a className="box" href="mailto:reachout@aciesglobal.com" target="_blank" rel="noopener noreferrer">
             <div className="reach-out-heading">Mail us</div>
             <div className="icon mail-icon" ></div>
             <div className="contact-info mail-info">
               <div>reachout@</div>
               <div>aciesglobal.com</div>
             </div>
           </a>
           <a className="box" href="https://www.linkedin.com/company/acies-global" target="_blank" rel="noopener noreferrer">
             <div className="reach-out-heading">Social media</div>
             <div className="icon linkedin-icon"></div>
             <div className="contact-info"></div>
           </a>
         </div>
      </div>
    );
  }
}

export default ReachOutForm;