import React, { Component } from 'react';
import './Insights.css';
import insights from '../../contents/insights.json'
import {Link} from 'react-router-dom';
import random_pick from '../../helpers/random_pick'

class Insights extends Component {
  constructor(props){
    super(props);
    this.state = {
      insight: insights[0],
      more_insights: [insights[1],insights[2]],
      mobile_view: false
    };
  }

  handle_screen_resize = (e) => 
  {
    this.setState({mobile_view: window.innerWidth<540})
  }

  random_insight()
  {
    var arr = []
    var output = random_pick(insights.length, 3)
    for(var i=0;i<output.length && arr.length<2 ;i++)
    {   
        if(insights[output[i]].heading !== this.props.match.params.id)
        { 
          arr.push(insights[output[i]])
        }
    }
    return arr
  }

  set_insight_from_path()
  {
    let data=insights.find(x=>x.heading === this.props.match.params.id);
    if(data !== undefined)
    {
      this.setState({insight:data,more_insights:this.random_insight()})
    }
  }

  // componentWillMount(){}
  componentDidMount(){
    this.set_insight_from_path();
    window.addEventListener('resize',this.handle_screen_resize);
  }
  // componentWillUnmount(){}
  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) 
    {
      if(this.props.location.content !== undefined) 
      {
        this.setState({insight:this.props.location.content.item,more_insights:this.random_insight()}) 
      }
      else
      {
        this.set_insight_from_path()
      }
    }
  }

   componentWillUnmount(){
    window.removeEventListener('resize',this.handle_screen_resize);
   }
  render() {
    return (
      <>  
        <header className="header-banner">
          <img src={this.state.mobile_view || window.innerWidth<540 ?this.state.insight.mobile_image:this.state.insight.banner_image} alt="Insights"></img>
          <section className="header-banner-content">
              <h1>{this.state.insight.heading}</h1>
          </section>
        </header>
          <div className="insight-main">
              <div className="insight-content">
                {this.state.insight.main_content.map((item, index)=>{
                    switch(item.type)
                    {
                      case "paragraph":
                        return <p key={index}>{item.data}</p>

                      case "heading":
                        return <h3 key={index}>{item.data}</h3>
                      
                      case "div":
                        return <div key={index}>{item.data}</div>

                      case "div with inner html":
                        return <div key={index} dangerouslySetInnerHTML={{__html: item.data}}></div>

                      case "list":
                        return (
                          <ul key={index}>
                            {item.list_items.map((list_item, index)=>{
                              return <li key={index}>{list_item.data}</li>
                            })}
                          </ul>
                        )
                      default:
                        return <div key={index}>{item.data}</div>
                      }
                })}
              </div>
              <div className="more-insights">
                  <div className="more-insights-heading">Read More</div>
                  {this.state.more_insights.map((item, methodIndex) => {
                      return( 
                          <Link to={{pathname:item.path, content:{item}}} key={methodIndex} className="more-insight">
                              <img className="more-insight-image" src={item.image} alt=""/>
                              <div className="more-insight-name">{item.heading}</div>
                              <div className="more-insight-info">{item.content}</div>
                          </Link>
                      )
                      })}
              </div>
          </div>
      </>
    );
  }
}

export default Insights;