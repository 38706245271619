import React, { Component } from 'react';
import principles from '../../../contents/Principles.json'
import './PrincipleCard.css';
class PrincipleCard extends Component {
    state = {  }
    render() { 
        return ( 
            <div className= {"bg-" + this.props.principle + " padding-1"}>
                <h3>{principles[this.props.principle].heading}</h3>
                <ul>
                    {principles[this.props.principle].content.map((principle,index) => (
                        <li key={index}>{principle}</li>
                    ))}
                </ul>
            </div>
         );
    }
}
 
export default PrincipleCard;