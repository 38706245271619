import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import './Service.css';
import {Link} from 'react-router-dom';
import case_studies from '../../contents/case_study.json'
import serviceData from './service.json'

class service extends Component {
  constructor(props){
    super(props);
    this.state = {
      basePath:"../../assets/Services/",
      serviceDetails:serviceData[0],
      caseStudies:[],
      mobile_view: false
    };


  }
  random_pick(input_length, random_count) {
    var arr=[]
    while(arr.length < random_count) 
    {
        var randomNumber = Math.random();
        var ind  = Math.floor(randomNumber * input_length);
        if(!arr.includes(ind))
        {
            arr.push(ind)
        }
    }
    return arr;
}

filter_by_tag(keywords)
{
  let result = []
  for(let i=0;i<case_studies.length;i++)
  {   
      if(case_studies[i].keywords.includes(keywords))
      { 
        result.push(case_studies[i])
      }
  }
  if(result.length<3)
  {
    for(let i=0;result.length<3;i++)
    {   
        if(!result.includes(case_studies[i]))  result.push(case_studies[i]);
    }
    return result;
  }
  else if(result.length>3)
  {
    var output = this.random_pick(result.length, 3)
    var new_result = []
    for(let i=0;i<output.length;i++)
    {
        new_result.push(result[output[i]]);
    }
    return new_result;
  }
  return result;
}

handle_screen_resize = (e) => {
  if(window.innerWidth<540) 
  {
    this.setState({mobile_view: true})
  }
  else
  {
    this.setState({mobile_view: false})
  }
}

  componentDidMount(){
    window.addEventListener('resize',this.handle_screen_resize);
    let data=serviceData.find(x=>x.name === this.props.match.params.service);
    if(data !== undefined)
    {
    this.setState({serviceDetails:data})
    }
    this.setState({caseStudies:this.filter_by_tag(data.keywords)});   
}

componentDidUpdate(prevProps) {
  if (prevProps.match.params.service !== this.props.match.params.service) 
  {
    let data=serviceData.find(x=>x.name === this.props.match.params.service);
    this.setState({serviceDetails:data})
    this.setState({caseStudies:this.filter_by_tag(data.keywords)});
  }
} 


componentWillUnmount(){
  window.removeEventListener('resize',this.handle_screen_resize);
}

  render() {
    return (
          
    <div className="service-component">
    <header className="header-banner">
          <img src={this.state.mobile_view || window.innerWidth<540 ?this.state.serviceDetails.banner_image_mobile:this.state.serviceDetails.banner_image} alt={this.state.serviceDetails.banner_title}></img>
          <section className="header-banner-content">
              <h1>{this.state.serviceDetails.banner_title}</h1>
              <p>{this.state.serviceDetails.banner_content}</p>
          </section>
    </header>

<LazyLoad once offset={100} className="service-row-2">
  <img src={"/assets/Services/"+this.state.serviceDetails.image} alt="" />
  <div className="service-row-2-content">
    <div className="service-row-2-content-1">
        {this.state.serviceDetails.description.map((item, index)=>(
          <p key={index}>{item.para}</p>
        ))}
    </div>
    <h1 className="service-row-2-content-2">What we do</h1>
    <div className="service-row-2-content-3">
    
   
     
      
     {this.state.serviceDetails.what_we_do.map((item, methodIndex) => {
       return( 
        <div key={methodIndex}>
       <div className="service-row-2-content-3-subtitle">{item.sub_title}</div>
        
       <ul>
       { item.sub_content.map((content, i) => <li key={i}>
            {content}
           
            </li>)}
       </ul>
       </div>
       )
 
})}
    
    </div>
  </div>
</LazyLoad>
<LazyLoad once offset={50} className="service-row-3">
  <img src={this.state.serviceDetails.banner_image_2} alt=""></img>
  <div className="service-row-3-content">
  {this.state.serviceDetails.banner_2_content}
  </div>
</LazyLoad>

<LazyLoad once offset={30} div className="service-row-4">
{this.state.caseStudies.map((item, methodIndex) => {
       return( 
         
          <Link to={{pathname:item.path, content:{item}}} key={methodIndex} className="service-row-4-content">
              <img className="service-row-4-content-banner-image" src={item.image} alt=""/>
              <div className="service-row-4-content-banner-name">
              
               {item.heading}
              </div>
            </Link>
       )
      })}
</LazyLoad>
</div>
    );
  }
}


export default service;