import React from 'react';
import './Card.css';
const Card = ({image,title,text}) => {
    return ( 
        <div className="Card">
            <img src={image} alt={'logo'} />
            <h1 className="white-text">{title}</h1>
            <p className="white-text">{text}</p>
        </div>
     );
}
 
export default Card;